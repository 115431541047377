import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font, PDFViewer } from '@react-pdf/renderer';
import { observer } from 'mobx-react';
import { formatNumber } from 'utils/formatHelper';
import { useParams } from 'react-router-dom';
import { purchaseOrderPresenter } from 'stores/root_store';
import { PurchaseOrderDetailModel } from 'models/PurchaseOrder';
import { Button, QRCode, Spin } from 'antd';
import moment from 'moment';
import { BsDot } from 'react-icons/bs';
import logo from 'assets/images/logo-tiny.png';
// Register font
Font.register({
    family: 'Roboto',
    fonts: [
        {
            src: `${window.location.origin}/Font/Roboto-Light.ttf`,
            fontWeight: 300,
            fontStyle: 'normal',
        },
        {
            src: `${window.location.origin}/Font/Roboto-LightItalic.ttf`,
            fontWeight: 300,
            fontStyle: 'italic',
        },
        {
            src: `${window.location.origin}/Font/Roboto-Regular.ttf`,
            fontWeight: 400,
            fontStyle: 'normal',
        },
        {
            src: `${window.location.origin}/Font/Roboto-Italic.ttf`,
            fontWeight: 400,
            fontStyle: 'italic',
        },
        {
            src: `${window.location.origin}/Font/Roboto-Medium.ttf`,
            fontWeight: 500,
            fontStyle: 'normal',
        },
        {
            src: `${window.location.origin}/Font/Roboto-MediumItalic.ttf`,
            fontWeight: 500,
            fontStyle: 'italic',
        },
        {
            src: `${window.location.origin}/Font/Roboto-Bold.ttf`,
            fontWeight: 700,
            fontStyle: 'normal',
        },
        {
            src: `${window.location.origin}/Font/Roboto-BoldItalic.ttf`,
            fontWeight: 700,
            fontStyle: 'italic',
        },
    ],
});


Font.registerHyphenationCallback(word => [word]);

// Create styles
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        position: 'relative',
        padding: "20px",
    },
    header: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    title: {
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: 700,
    },
    serial: {
        marginTop: "3px",
        fontStyle: 'italic',
        fontSize: 7,
        fontWeight: 400
    },
    imgBox: {
        width: "70px",
        height: "70px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px",
        borderColor: '#e7e7e7',
        borderRadius: '2px',
        marginTop: '14px',
    },
    img: {
        width: "65px",
        height: "65px",
    },
    infor: {
        marginTop: "10px",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        // width: "83%",
        fontSize: "18px",
        // position: 'absolute',
        // top: '58px',
        // right: '30px',
        gap: '10px'
    },
    inforText: {
        display: 'flex',
        fontSize: '10px',
        fontWeight: 400,
        paddingBottom: '2px',
        flexWrap: 'wrap'
    },
    textBold: {
        fontWeight: 500,
    },
    table: {
        marginTop: '10px',
        fontSize: '10px',
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        borderTop: 1,
        borderColor: 'black'
    },
    tableCell: {
        borderLeft: 1,
        borderColor: 'black',
        padding: '4px 2px',
        fontWeight: 400
    },
    contentTop: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #333',
        width: '100%',
    },
    logo: {
        width: 70,
        margin: "0 auto"
    },

});

// Create Document Component

export function createPrintPDF() {
    return observer(() => {
        const { deliveryRegistrationId } = useParams();
        const [dataDetail, setDataDetail] = useState<PurchaseOrderDetailModel | undefined>(undefined)
        const [qrImg, setQRImg] = useState<string | undefined>(undefined)
        // chi tiết phiếu đăng ký theo id
        const getDetailDeliveryRegistration = async () => {
            const detail = await purchaseOrderPresenter.getDetailDeliveryRegistration(deliveryRegistrationId || '')
            if (detail) {
                setDataDetail(detail)
            }
        }
        const downloadQRCode = () => {
            const canvas = document.getElementById('myqrcode')?.querySelector<HTMLCanvasElement>('canvas');
            if (canvas) {
                // const base64String = canvas.toDataURL().replace('data:image/png;base64,', '');
                const base64String = canvas.toDataURL();
                // const byteCharacters = atob(base64String);
                // const byteNumbers = new Array(byteCharacters.length);
                // for (let i = 0; i < byteCharacters.length; i++) {
                //     byteNumbers[i] = byteCharacters.charCodeAt(i);
                // }
                // const byteArray = new Uint8Array(byteNumbers);
                // const blob = new Blob([byteArray], { type: 'image/jpeg' });

                // // Tạo URL đối tượng từ Blob
                // const blobUrl = URL.createObjectURL(blob);
                // console.log(base64String);

                // console.log(blobUrl);
                setQRImg(base64String)
            }
        };

        useEffect(() => {
            downloadQRCode()
        }, [dataDetail]);

        useEffect(() => {
            getDetailDeliveryRegistration()
        }, []);
        return !dataDetail ?
            (<div className='w-full h-full flex justify-center items-center'>
                <Spin />
            </div>) :
            (<>
                <div id="myqrcode" className='hidden'>
                    <QRCode value={dataDetail.deliveryRegistrationId} bgColor="#fff" style={{ marginBottom: 16 }} />
                </div>
                <PDFViewer style={{ width: '100vw', height: '100vh' }}>
                    <Document >
                        <Page wrap size="A4" style={styles.page} orientation="landscape">
                            <View style={styles.header}>
                                {/* <View style={styles.imgBox}>
                                    {qrImg && <Image style={styles.img} source={qrImg} />}
                                </View>
                                <Text style={styles.title}>Phiếu đăng ký xe giao hàng</Text>
                                <Text style={styles.serial}>Số phiếu: {dataDetail.deliveryRegistrationCode}</Text> */}
                                <View style={styles.contentTop}>
                                    <View
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        {/* Logo */}
                                        <View style={{ padding: '12px', width: 'auto', borderRightWidth: '1px' }}>
                                            <Image style={{
                                                width: "70px",
                                                margin: "0 auto",
                                                marginTop: "12px"
                                            }} src={logo} />
                                        </View>
                                        {/* QRCode */}
                                        <View style={{ padding: '12px', paddingBottom: "4px", width: 'auto' }}>
                                            {qrImg && <Image style={{
                                                width: 80,
                                                margin: "0 auto",
                                                marginBottom: "10px"
                                            }} src={qrImg} />}
                                            <Text style={styles.serial}>Số phiếu: {dataDetail.deliveryRegistrationCode}</Text>
                                        </View>
                                    </View>
                                    {/* Thông tin bên trái */}
                                    <View
                                        style={{
                                            borderLeft: '1px solid #333',
                                            width: '80%',
                                            fontSize: '12px',
                                        }}
                                    >
                                        <View
                                            style={{
                                                borderBottom: '1px solid #333',
                                                fontWeight: 500,
                                                padding: '12px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <Text style={{ fontWeight: 700, textTransform: "uppercase" }}>Công ty AJINOMOTO Việt Nam</Text>
                                        </View>
                                        <View
                                            style={{
                                                fontWeight: 500,
                                                padding: '12px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <Text style={{ fontWeight: 700, textTransform: "uppercase" }}>PHIẾU GIAO HÀNG </Text>
                                            <Text style={{ fontWeight: 400, textTransform: "uppercase", fontSize: "11px" }}>DELIVERY NOTE </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.infor}>
                                <View style={{ width: '50%' }}>
                                    <Text style={styles.inforText}>
                                        <Text style={styles.textBold}>Nhà cung cấp/Vendor:</Text>
                                        {dataDetail.vendorNumber + " | "}{dataDetail.vendorName}
                                    </Text>
                                    <Text style={styles.inforText}><Text style={styles.textBold}>Tài xế/Driver:</Text> {dataDetail.driverName}</Text>
                                    <Text style={styles.inforText}><Text style={styles.textBold}>Số xe/Truck No:</Text> {dataDetail.licensePlate}</Text>
                                    <Text style={styles.inforText}><Text style={styles.textBold}>Kết quả đào tạo/Training Results:</Text> {dataDetail.resultName}</Text>
                                </View>
                                {/* <Text ></Text>  */}
                                <View style={{ width: '50%' }}>
                                    <View style={{
                                        display: 'flex',
                                        fontSize: '10px',
                                        fontWeight: 400,
                                        paddingBottom: '2px',
                                        flexWrap: 'wrap'
                                    }}>
                                        <Text style={{ ...styles.textBold, marginBottom: '2px' }}>Thời gian giao hàng/Delivery Time:
                                            {dataDetail.deliveryTimeItems.length ? dataDetail.deliveryTimeItems.map((item, index) => {
                                                return (
                                                    // <Text style={{ width: '100%', paddingBottom: '2px' }} key={index}>
                                                    <Text>
                                                        {/* <Text style={styles.textBold}>    &#8226;   Đợt {index + 1}: </Text> */}
                                                        <Text style={styles.textBold}> {dataDetail.periodTimeName + ", "}</Text>
                                                        <Text>{item.deliveryTime ? moment(item.deliveryTime).format("DD/MM/YYYY HH:mm:ss") : ''}</Text>
                                                    </Text>
                                                    // </Text>
                                                )
                                            }) : ''}
                                        </Text>
                                    </View>
                                    <Text style={styles.inforText}><Text style={styles.textBold}>Số bằng lái xe/Driver's License No:</Text> {dataDetail.licenseNumber}</Text>
                                    <Text style={styles.inforText}><Text style={styles.textBold}>Số điện thoại/Tel:</Text> {dataDetail.phone}</Text>
                                </View>
                            </View>
                            <View style={styles.infor}>
                                <View style={{ width: '50%', border: "1px solid black", padding: "7px" }}>
                                    <Text style={styles.inforText}><Text style={styles.textBold}>Đơn vị nhận/Receiver:</Text> Công ty Ajinomoto Việt Nam</Text>
                                    <Text style={styles.inforText}><Text style={styles.textBold}>Địa chỉ/Address: </Text> Khu công nghiệp Biên Hòa 1 - Đồng Nai - Việt Nam</Text>
                                    <Text style={styles.inforText}><Text style={styles.textBold}>MST/Tax code:</Text> 3600244645</Text>
                                    <Text style={styles.inforText}><Text style={styles.textBold}>Địa chỉ giao hàng/Shipping Address:</Text>Khu công nghiệp Biên Hòa 1 - Đồng Nai - Việt Nam </Text>
                                    <Text style={styles.inforText}><Text style={styles.textBold}>Người liên hệ/Contact Person: </Text> </Text>
                                </View>
                                <View style={{ width: '50%', border: "1px solid black", padding: "7px" }}>
                                    <Text style={styles.inforText}><Text style={{ textAlign: "center", fontWeight: 700 }}>Cân xe đầu vào (nếu cần)/Gross weight scalling</Text> </Text>
                                    {/* <View style={styles.inforText}>
                                        <Text style={{ ...styles.textBold, marginBottom: '2px' }}>Thời gian giao hàng:</Text>
                                        {dataDetail.deliveryTimeItems.length ? dataDetail.deliveryTimeItems.map((item, index) => {
                                            return (
                                                <Text style={{ width: '100%', paddingBottom: '2px' }} key={index}>
                                                    <Text style={styles.textBold}>    &#8226;   Đợt {index + 1}: </Text><Text>{item.deliveryTime ? moment(item.deliveryTime).format("DD/MM/YYYY HH:mm:ss") : ''}</Text>
                                                </Text>
                                            )
                                        }) : ''}
                                    </View>
                                    <Text style={styles.inforText}><Text style={styles.textBold}>Số bằng lái xe:</Text> {dataDetail.licenseNumber}</Text>
                                    <Text style={styles.inforText}><Text style={styles.textBold}>Số điện thoại:</Text> {dataDetail.phone}</Text> */}
                                </View>
                            </View>
                            <View style={styles.table}>
                                <View style={styles.tableRow}>
                                    <View style={{ ...styles.tableCell, fontWeight: 500, width: '4%', textAlign: 'center' }}><Text>STT</Text></View>
                                    <View style={{ ...styles.tableCell, fontWeight: 500, width: '9%', textAlign: 'center' }}><Text>PO</Text></View>
                                    <View style={{ ...styles.tableCell, fontWeight: 500, width: '5%', textAlign: 'center', }}><Text>Item</Text></View>
                                    <View style={{ ...styles.tableCell, fontWeight: 500, width: '9%', textAlign: 'center' }}><Text>Mã hàng</Text></View>
                                    <View style={{ ...styles.tableCell, fontWeight: 500, width: '15%', textAlign: 'center' }}><Text>Tên hàng</Text></View>
                                    <View style={{ ...styles.tableCell, fontWeight: 500, width: '12%', textAlign: 'center', }}><Text>SL đặt hàng</Text></View>
                                    <View style={{ ...styles.tableCell, fontWeight: 500, width: '9%', textAlign: 'center', }}><Text>SL nhập kho</Text></View>
                                    <View style={{ ...styles.tableCell, fontWeight: 500, width: '10%', textAlign: 'center', }}><Text>SL kế hoạch</Text></View>
                                    <View style={{ ...styles.tableCell, fontWeight: 500, width: '12%', textAlign: 'center', }}><Text>SL giao hàng</Text></View>
                                    <View style={{ ...styles.tableCell, fontWeight: 500, width: '3%', textAlign: 'center' }}><Text>ĐVT</Text></View>
                                    <View style={{ ...styles.tableCell, fontWeight: 500, width: '12%', borderRightWidth: 1, textAlign: 'center' }}><Text>Kho</Text></View>
                                </View>
                                {dataDetail?.deliveryInfoItems.map((item, index) => (
                                    <View key={index} style={styles.tableRow}>
                                        {/*  */}
                                        <View style={{ ...styles.tableCell, paddingTop: 2, paddingBottom: 2, width: '4%', textAlign: 'center' }}><Text>{index + 1}</Text></View>
                                        {/* Số po */}
                                        <View style={{ ...styles.tableCell, paddingTop: 2, paddingBottom: 2, width: '9%' }}><Text>{item.purchaseOrderCode}</Text></View>
                                        {/* po item */}
                                        <View style={{ ...styles.tableCell, paddingTop: 2, paddingBottom: 2, width: '5%', textAlign: 'center', }}><Text>{item.poItem}</Text></View>
                                        {/* mã hàng hóa */}
                                        <View style={{ ...styles.tableCell, paddingTop: 2, paddingBottom: 2, width: '9%' }}><Text>{item.productCode}</Text></View>
                                        {/* tên hàng hóa */}
                                        <View style={{ ...styles.tableCell, paddingTop: 2, paddingBottom: 2, width: '15%' }}><Text>{item.productName}</Text></View>
                                        {/* SL đặt hàng */}
                                        <View style={{ ...styles.tableCell, paddingTop: 2, paddingBottom: 2, width: '12%', textAlign: 'right', }}><Text>{formatNumber(item.totalQuantity, 3)}</Text></View>
                                        {/* SL nhập kho */}
                                        <View style={{ ...styles.tableCell, paddingTop: 2, paddingBottom: 2, width: '9%', textAlign: 'right', }}><Text>{formatNumber(item.receivedQuantity || 0, 3)}</Text></View>
                                        {/* Sl còn lại */}
                                        <View style={{ ...styles.tableCell, paddingTop: 2, paddingBottom: 2, width: '10%', textAlign: 'right', }}><Text>{formatNumber(item.openQuantitySAP, 3)}</Text></View>
                                        {/* SL giao hàng */}
                                        <View style={{ ...styles.tableCell, paddingTop: 2, paddingBottom: 2, width: '12%', textAlign: 'right', }}><Text>{formatNumber(item.deliveredQuantity, 3)}</Text></View>
                                        <View style={{ ...styles.tableCell, paddingTop: 2, paddingBottom: 2, width: '3%' }}><Text>{item.orderUnit}</Text></View>
                                        {/* Kho */}
                                        <View style={{ ...styles.tableCell, paddingTop: 2, paddingBottom: 2, width: '12%', borderRightWidth: 1 }}><Text>{item.stockFmt}</Text></View>
                                    </View>
                                ))}
                                <View style={styles.tableRow}></View>
                            </View>
                        </Page>
                    </Document>
                </PDFViewer >
            </>)
    })
}

